<template>
    <div style="margin-top: 8px;">
        <div v-if="showDoctorLoader" style="display: flex; flex: 1; height:60vh; align-items:center; justify-content: center;">
                    <img src="https://s3iconimages.mymedicine.com.mm/doctorSearchGif.gif" alt="" style="width: 250px; height: auto;">
                </div>
        <div v-else>
                <div v-if="getDoctorList.length == 0" style="display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 4px; color: grey;">
                        <img src="https://s3iconimages.mymedicine.com.mm/noContentFound.svg" alt="" style="width: 80px; height: 80px;">
                        {{$t("Customer.ViewDoctorsPage.No_Doctors_Found")}}
                    </div>     
            <div  v-else >
                <view-particular-doctor-doc-flow v-for="(item, index) in getDoctorList" :key="item.doctorInfo.id" :item="item" :dependent_members="dependent_members" @toggleSlot="toggleSlot" :isSelectedDoctorBooking="selectedDoctorBooking"/>
            </div>
        </div>

    </div>
</template>

<script>
import BodyContainer from '../../../../templates/bodyContainer.vue';
import HeaderContainer from '../../../../templates/headerContainerOPDBooking.vue';
import mainContainer from '../../../../templates/mainContainer.vue';
import { axios_auth_instance_hba_customer } from '../../../../utils/axios_utils';
import ViewParticularDoctorDocFlow from '../opdHomePage/singleDoctorCompDoctorFlow.vue';
import HospitalSkeletonLoader from './hospitalSkeletonLoader.vue';



export default {
  components: { mainContainer, BodyContainer, HeaderContainer, ViewParticularDoctorDocFlow, HospitalSkeletonLoader },
    name: 'DoctorFlowComp',
    // props: ['hospitalData'],
    data: function() {
        return {
            todayAppointment: [],
            pageLoading: true,
            ongoingAppointment: [],
            bannerURL: '',
            item: {
			"doctorInfo": {
                    "id": "ABC123445",
                    "academicTitle": [
                        "M.Med.Sc (Pediatrics)",
                        "M.B.B.S (Ygn)"
                    ],
                    "doctorLogo": "https://media.istockphoto.com/id/1365830421/vector/hands-holding-house-symbol-with-heart-shape-thick-line-icon-with-pointed-corners-and-edges.jpg?s=612x612&w=0&k=20&c=OcBjtznQ1DKxk07kYzVxH-UC9-QC6HtKlRU5cNGcmfM=",
                    "doctorNameEN": "Dr. Kuldeep Watch King",
                    "doctorNameMM": "Dr. Kuldeep Watch King",
                    "category": "Internal Medicine",
                    "location": "Yangoon",
                    "doctorSpecialty": [
                        "12341",
                        "12342"
                    ],
                    "hospitalRegNo": "ABUHK4451"
			    }
            },
            dependent_members: [],
            selectedDoctorBooking: -1,


        }    
    },
    computed: {
        showDoctorLoader () {
            return this.$store.state.opdHospitalState.fetchingDoctorData;        
        },
        getDoctorList() {
            return this.$store.state.opdHospitalState.showDoctorListDoctorFlow || [];        
        }
    },
    async mounted() {
        this.getDependentData();
        this.$store.dispatch('loadDoctorListDoctorFlow');
    },
    methods: {
        goToHospitalPage() {
            // this.$router.push({
            // path: `/viewHospital/${this.hospitalData.hospitalRegNumber}`,
            // });
        },
        toggleSlot(item) {
            this.selectedDoctorBooking = item.id;
        },
        async getDependentData() {
            this.currentCustomer = this.$cookies.get("customerToken");
                let customerData = {
                token: this.currentCustomer,
                typeOfUser: "CUSTOMER",
            };
            try {
                let dependentResponse = await axios_auth_instance_hba_customer.get("/user/dependents");
                var dependent_list = [];
                var dependent_members = dependentResponse.data.dependentList;
                this.dependent_members = dependent_members;
                this.dependent_members.push({
                    _id: 'Myself',
                    dependent_name: 'Myself',
                    NRC: this.NRC,
                });
            } catch (error) {
                console.log(error);
            } 
        },
    },
};

</script>
<style  scoped>
.mainContainer {
    padding: 16px;
    background: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    cursor: pointer;
}
.headerHeading {
    color: white;
    font-weight: 600;
}
.hospitalName {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.hospitalNameAndLogo {
    display: flex;
    align-items: center;
    gap: 16px;
}
</style>